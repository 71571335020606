import React, { useState } from 'react'
import Video from "../../assests/videos/video.mp4"
import {Button} from "../ButtonElement" 
import Particles from 'react-particles-js';
import {
    HeroContainer,
    HeroBg,
    VideoBg,
    HeroContent,
    HeroH1,
    HeroP,
    HeroBtnWrapper,
    ArrowForward,
    ArrowRight
    
    
} from "./HeroElements"

const Hero = () => {
    const [hover, setHover] = useState(false)
    
    const onHover = () =>{
        setHover(!hover)
    }
    
    
    return (

        <HeroContainer id="Hero">
            <HeroBg>
            <Particles 
                params={{ 
                particles: { 
                number: { 
                value: 200, 
                density: { 
                enable: true, 
                value_area: 1000
            }
            },
                color: {
                value: '#F9A826'
            },
                opacity: {
                value: 0.9,
                anim: {
                enable: true
            }
            },
                size: {
                value: 7,
                random: true,
                anim: {
                enable: true,
                speed: 3
            }
            },
                line_linked: {
                enable: false
            },
                move: {
                speed: 0.9
            }
           }    
         }} 
       /> 
                {/* <VideoBg autoPlay loop muted src={Video} type= "video/mp4"/> */}
            </HeroBg>
            <HeroContent>
                <HeroH1> Hey! I'm Claire.{"\n"}
                I build applications for the web.</HeroH1>
                <HeroP>Software Engineer & Web Developer</HeroP>
                <HeroBtnWrapper>
                    <Button 
                    to = "Projects" 
                    onMouseEnter= {onHover} 
                    onMouseLeave = {onHover}
                    primary="true"
                    dark="true">
                    View my Work {hover ? <ArrowForward/>: <ArrowRight/>}
                    </Button>
                </HeroBtnWrapper>
                
            </HeroContent>
            
        </HeroContainer>
    )
}

export default Hero
